import Vue from "vue";
import Router from "vue-router";
import AuthRequired from "./core/auth/AuthRequired";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      beforeEnter : AuthRequired,
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/page/Dashboard")
        },
        {
          path: "/page-content",
          name: "page-content",
          component: () => import("@/page/PageContent"),
          children: [
            {
              path: "home",
              name: "PageContentHome",
              component: () => import("@/page/PageContent/Home")
            },
            {
              path: "meeting-room",
              name: "PageContentMeetingRoom",
              component: () => import("@/page/PageContent/MeetingRoom")
            },
            {
              path: "contact-us",
              name: "PageContentContactUs",
              component: () => import("@/page/PageContent/ContactUs")
            },
            {
              path: "header",
              name: "PageContentHeader",
              component: () => import("@/page/PageContent/Header")
            },
            {
              path: "footer",
              name: "PageContentFooter",
              component: () => import("@/page/PageContent/Footer")
            },
          ]
        },
        {
          path: "/rooms",
          name: "rooms",
          component: () => import("@/page/Rooms")
        },
        {
          path: "/promo",
          name: "promo",
          component: () => import("@/page/Promo")
        },
        {
          path: "/gallery",
          name: "gallery",
          component: () => import("@/page/Gallery")
        },
        {
          path: "/journal",
          name: "journal",
          component: () => import("@/page/Journal")
        },
      ]
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/page/Auth/Login")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ],
  // mode : 'history'
});
