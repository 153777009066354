export default {
  state: {
    currentUser: localStorage.getItem('QBKAdmin') != null && typeof localStorage.getItem('QBKAdmin') !== undefined ? JSON.parse(localStorage.getItem('QBKAdmin')) : null,
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    setForgotMailSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess = true
    },
    setResetPasswordSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess = true
    },
    clearError(state) {
      state.loginError = null
    }
  },
  actions: {
    login({
      commit
    }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      console.log(payload)
      if((payload.email == 'superadmin@qubikahotel.com' && payload.password == 'superadmin') || (payload.email == 'admin@qubikahotel.com' && payload.password == 'admin')){
        let item = {
          id : '1',
          username : 'Superadmin',
          name : 'Superadmin',
          email : 'superadmin@qubikahotel.com',
          phone : '08121913683',
          role : 'Admin',
          img: 'media/users/default.jpg',
        }
        localStorage.setItem('QBKAdmin', JSON.stringify(item))
        commit('setUser', item)
      }
    },
    updateUser({
      commit
    }, payload) {
        localStorage.setItem('QBKAdmin', JSON.stringify(payload.profile))
        commit('setUser', payload.profile)
    },
    signOut({
      commit
    }) {
      localStorage.removeItem('QBKAdmin')
      commit('setLogout')
    }
  }
}
